

import React from "react";

export interface Job {
    title: string;
    description: JSX.Element;
    period?: string;
    whatYouWillBeDoing: string[];
    requirements: string[];
    video?: {
        url: string;
        thumbnail: string;
    };
    interested: JSX.Element | string;
}

export const jobs: Array<Job> = [  
  
      {
        title: 'Internships',
        description: (
            <>
                <p>
                    We are looking for a Project Management Intern to contribute to our following iii (IIImagine / AiiiR) shows.
                </p>
                <p>
                    We work at the intersection of art and technology to create unique live shows. With sold-out shows at iconic venues like the Atomium in Brussels, the National Maritime Museum in Amsterdam, Acquario Romano in Rome, and upcoming shows across Europe, we aim to redefine both spaces and shows.
                </p>
                <p>
                    Our team is small and highly motivated. As we believe in the transformative power of multi-disciplinary collaboration, our start-up team unites knowledge from fields as varied as design, music, science, finance, or architecture.  
                </p>
                <p>
                    We are looking for someone who is not only a good person but also strives for excellence in what they do. Together, we believe that a very strong team can accomplish a great deal.
                </p>
            </>
        ),
        period: 'Flexible internship periods lasting several months are available',
        whatYouWillBeDoing: [
            'Assist in expanding IIIMAGINE presence to new, global locations',
            'Engage with various business aspects, sharpening your entrepreneurial skills',
            'Manage show operations, dealing with thousands of attendees in dynamic settings',
            'Participate in brainstorming sessions, crafting solutions aligned with the best interests of our audience',
            'We will guide you in becoming deeply integrated into the iii vision and prepare you for a leadership role within the company',
            'We will guide you in becoming deeply integrated into the IIIMAGINE vision while offering substantial autonomy',
        ],
        requirements: [
            'Thrive on curiosity',
            'Highly motivated',
            'You have a broad interest and consider yourself open-minded',
            'Strong communication skills',
            'You can work from our office in Ghent (Belgium)',
            'You are eager to take ownership in a startup culture',
        ],
        interested: (
            <>    
                <p>
                    Please email your CV and cover letter to <a
                        href="mailto:mathias@iiimagine.org"
                        className="text-iiimagine-red">mathias@iiimagine.org</a>
                </p>
                <br />
    
            </>
        )
    }
    

]

